@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "bootstrap/scss/bootstrap";

body {
  margin: 0;
  line-height: normal;
}
:is(.yellow-button-sm, .blue-button-sm, .white-button-sm) {
  display: inline-block;
  align-content: center;
  width: fit-content;
  height: 32px;
  max-height: 32px;
  white-space: nowrap;
  min-height: 32px;
  border-radius: 4px;
  padding-inline: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  border: 0;
}
:is(
    .yellow-button-md,
    .blue-button-md,
    .white-button-md,
    .blue-button-rounded-md,
    .white-button-rounded-md,
    .blue-button-auth-lg
  ) {
  display: inline-block;
  align-content: center;
  width: fit-content;
  height: 44px;
  max-height: 44px;
  white-space: nowrap;
  min-height: 44px;
  border-radius: 8px;
  padding-inline: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border: 0;
}
:is(.yellow-button-lg, .blue-button-lg, .white-button-lg) {
  display: inline-block;
  align-content: center;
  width: fit-content;
  white-space: nowrap;
  height: 56px;
  max-height: 56px;
  min-height: 56px;
  border-radius: 12px;
  padding-inline: 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  border: 0;
}
.yellow-button-sm,
.yellow-button-md,
.yellow-button-lg {
  color: #0054a6;
  border: 1px solid #ffd602;
  background-color: #ffd602;
}
.yellow-button-lg {
  border: 2px solid #ffd602;
}
.blue-button-sm,
.blue-button-md,
.blue-button-lg,
.blue-button-auth-lg,
.blue-button-rounded-md,
.blue-button-auth-lg {
  color: #ffd602;
  border: 1px solid #0054a6;
  background-color: #0054a6;
}
.blue-button-lg,
.blue-button-auth-lg {
  border: 2px solid #0054a6;
}
.white-button-sm,
.white-button-md,
.white-button-lg {
  color: #0054a6;
  border: 1px solid #0054a6;
  background-color: white;
  border: 1px solid #0054a6;
}
.white-button-lg {
  border: 2px solid #0054a6;
}

// for auth
.blue-button-auth-lg {
  height: 50px;
}
.blue-button-rounded-md {
  border-radius: 22px !important;
  min-height: 40px;
}
.white-button-rounded-md {
  min-height: 40px;
  color: #0054a6;
  background-color: white;
  border-radius: 22px !important;
  border: 1px solid #0054a6;
}
.blue-button-xl {
  padding: 11.5px 20px;
  color: #ffd602;
  background-color: #0054a6;
  min-width: 172.5px;
  border-radius: 8px;
  font-size: 18px;
  min-height: 40px;
}
.blue-button-xl:hover {
  background-color: #267acc;
}
:is(.yellow-button-sm:hover, .yellow-button-md:hover, .yellow-button-lg:hover) {
  color: #ffd602;
  border: 1px solid #0054a6;
  background-color: #0054a6;
}
.yellow-button-lg:hover {
  border: 2px solid #0054a6;
}
:is(
    .blue-button-sm:hover,
    .blue-button-md:hover,
    .blue-button-lg:hover,
    .blue-button-auth-lg:hover,
    .blue-button-rounded-md:hover,
    .white-button-rounded-md:hover
  ) {
  color: #0054a6;
  border: 1px solid #ffd602;
  background-color: #ffd602;
}

.blue-button-lg:hover,
.blue-button-auth-lg:hover {
  border: 2px solid #ffd602;
}
.white-button-sm:hover,
.white-button-md:hover,
.white-button-lg:hover {
  background-color: #bebebe;
}
.white-button-sm:disabled {
  cursor: auto;
  color: #bebebe;
  border: 1px solid #bebebe;
}
.custom-title {
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: #272727 !important;
}
.custom-message {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  color: #686868 !important;
}
.custom-timer {
  background-color: #0054a6;
  height: 4px;
  top: 0px !important;
  bottom: auto;
}
.success-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.custom-popup {
  width: auto;
  border-radius: 16px !important;
  border: 1px solid white !important;
}
.swal2-title-custom {
  font-size: 24px !important; /* Adjust the font size */
  font-weight: 700 !important;
  line-height: 36px !important;
}
.swal2-confirm-custom {
  color: #ffd602 !important; /* Adjust the text color */
}
.swal2-cancel-custom {
  color: #0054a6 !important; /* Adjust the text color */
}
.field_label {
  font-size: 16px;
  font-weight: 400;
}
.requiredMark::after {
  content: "*";
  color: red;
  margin-left: 2px;
}
.cursor {
  cursor: pointer !important;
}
.center {
  justify-content: center !important;
  align-items: center !important;
}
.c_0054a6 {
  color: #0054a6;
}
::ng-deep .dx-overlay-wrapper.dx-popup-wrapper.dx-overlay-shader {
  z-index: 900 !important;
}

:root {
  // /* fonts */
  --body-b1-regular: Inter;
  --paragraph-default: "DM Sans";
  --font-Poppins: Poppins;
  --font-manrope: Manrope;
  --font-feature-settings: "liga" 1, "calt" 1;
  /* font sizes */
  --header-h1-bold-size: 48px;
  --font-size-sm: 14px;
  --paragraph-default-size: 18px;
  --font-size-xl: 20px;
  --body-b1-regular-size: 16px;
  --font-size-10xl-5: 29.5px;
  --header-h3-regular-size: 24px;
  --font-size-17xl: 36px;
  --font-size-10xl: 29px;
  --font-size-3xl: 22px;
  --font-size-lgi: 19px;
  --font-size-xs: 12px;
  /* Colors */
  --white-100: #fff;
  --black-100: #272727;
  --color-gray-100: rgba(39, 39, 39, 0.8);
  --color-gray-200: rgba(147, 147, 147, 0.5);
  --black-30: #bebebe;
  --secondary-colors-color-2: #f2f1ff;
  --yellow-10: #fffbe5;
  --blue-100: #0054a6;
  --color-cornflowerblue: #267acc;
  --yellow-100: #ffd602;
  --color-gainsboro-100: #e4e7ec;
  --black-70: #686868;
  --blue-10: #f2f9ff;
  --color-goldenrod: #cca300;
  --color-seashell: #fff6f2;
  --color-peachpuff: #ffc9b3;
  --yellow-50: #ffeb80;
  --blue-50: #80a9d3;
  --blue-30: #b3cce4;
  --yellow-30: #fff3b3;
  /* Gaps */
  --gap-8xl: 27px;
  --gap-xl: 20px;
  --gap-3xl: 22px;
  --gap-5xs: 8px;
  --gap-7xs: 6px;
  --gap-21xl: 40px;
  --gap-lg: 18px;
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-9xs: 4px;
  --gap-27xl: 46px;
  --gap-4xl: 23px;
  --gap-9xl: 28px;
  --gap-lg-7: 18.7px;
  --gap-6xs-5: 6.5px;
  --gap-45xl: 64px;
  --gap-3xs: 10px;
  --gap-xl-7: 20.7px;
  --gap-mini: 15px;
  /* Paddings */
  --padding-109xl: 128px;
  --padding-xl: 20px;
  --padding-64xl: 83px;
  --padding-8xl: 27px;
  --padding-2xs: 11px;
  --padding-xs: 12px;
  --padding-sm: 14px;
  --padding-11xs: 2px;
  --padding-35xl: 54px;
  --padding-45xl: 64px;
  --padding-13xl: 32px;
  --padding-23xl: 42px;
  --padding-2xl-5: 21.5px;
  --padding-base: 16px;
  --padding-7xs: 6px;
  --padding-mini: 15px;
  --padding-5xs: 8px;
  --padding-30xl: 49px;
  --padding-56xl: 75px;
  --padding-24xl: 43px;
  --padding-47xl: 66px;
  --padding-26xl: 45px;
  --padding-3xl: 22px;
  --padding-2xl: 21px;
  --padding-12xs: 1px;
  --padding-4xs-6: 8.6px;
  --padding-6xl: 25px;
  --padding-29xl: 48px;
  --padding-65xl: 84px;
  --padding-53xl: 72px;
  --padding-17xl: 36px;
  --padding-11xs-5: 1.5px;
  --padding-39xl: 58px;
  --padding-40xl: 59px;
  --padding-49xl: 68px;
  --padding-10xl: 29px;
  --padding-19xl: 38px;
  --padding-25xl: 44px;
  --padding-20xl: 39px;
  --padding-9xs: 4px;
  --padding-22xl: 41px;
  --padding-43xl: 62px;
  --padding-12xl: 31px;
  --padding-115xl-4: 134.4px;
  --padding-5xs-5: 7.5px;
  --padding-7xs-5: 5.5px;
  --padding-3xs: 10px;
  --padding-mid-1: 17.1px;
  /* Border radiuses */
  --br-5xs: 8px;
  --br-base: 16px;
  --br-xs: 12px;
  --br-3xl: 22px;
  --br-xl: 20px;
  --br-31xl: 50px;
  /* Effects */
  --shadow-lg: 0px 12px 16px -4px rgba(39, 39, 39, 0.08),
    0px 4px 6px -2px rgba(39, 39, 39, 0.03);

  // for forget password

  /* fonts */
  --font-nunito: Nunito;
  --font-poppins: Poppins;
  --header-h4-bold: Inter;

  /* font sizes */
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-17xl: 36px;
  --font-size-3xl: 22px;
  --font-size-10xl: 29px;
  --font-size-21xl: 40px;
  --font-size-13xl: 32px;
  --ui-16-medium-size: 16px;
  --header-h4-bold-size: 18px;
  --font-size-45xl: 64px;
  --font-size-19xl: 38px;
  --font-size-32xl: 51px;

  /* Colors */
  --yellow-10: #fffbe5;
  --black-100: #272727;
  --secondary-blue: #0054a6;
  --blue-10: #f2f9ff;
  --color-black: #000;
  --color-cornflowerblue: #267acc;
  --yellow-100: #ffd602;
  --bg-light-gray: #f7f7f7;
  --gray-100: #706f6f;

  /* Gaps */
  --gap-3xl-7: 22.7px;
  --gap-xl: 20px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-13xl: 32px;
  --padding-7xs: 6px;
  --padding-7xl: 26px;
  --padding-xs-5: 11.5px;
  --padding-4xl: 23px;
  --padding-12xs: 1px;
  --padding-5xl-8: 24.8px;
  --padding-2xs: 11px;
  --padding-base: 16px;

  /* Border radiuses */
  --br-3xs: 10px;
  --br-5xs: 8px;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}
